
  <template>
  <div class="layout--full-page">
    <div
      class="
        h-screen
        flex
        w-full
        bg-img
        vx-row
        no-gutter
        items-center
        justify-center
      "
      id="page-login"
    >
      <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
        <vx-card>
          <div slot="no-body">
            <div
              class="
                vx-row
                no-gutter
                justify-center
                items-center
                full-page-bg-color
              "
            >
              <div class="vx-col hidden lg:block lg:w-1/2">
                <img
                  src="@/assets/images/pages/login.png"
                  alt="login"
                  class="mx-auto"
                />
              </div>

              <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
                <div class="p-8 login-tabs-container">
                  <div class="vx-card__title mb-4">
                    <h4 class="mb-4">{{ $t("main.login") }}</h4>
                    <p>
                      {{ $t("main.Welcome_back_please_login_to_your_account") }}
                    </p>
                  </div>

                  <vs-tabs>
                    <vs-tab :label="$t('main.login')">
                      <transition name="slide">
                        <form
                          @keyup.enter="continueLoginAction ? login() : false"
                        >
                          <vs-input
                            v-validate="'required|email'"
                            name="email"
                            icon="icon-user"
                            icon-pack="feather"
                            :label-placeholder="
                              $t('auth.login_by_email_or_username')
                                | text_formatter
                            "
                            v-model="login_data.email"
                            class="w-full"
                          />
                          <span class="text-danger text-sm">{{
                            errors.first("email") | text_formatter
                          }}</span>

                          <vs-input
                            v-validate="'required|min:6'"
                            type="password"
                            name="password"
                            icon="icon-lock"
                            icon-pack="feather"
                            :label-placeholder="$t('main.password')"
                            v-model="login_data.password"
                            class="w-full mt-10"
                          />
                          <span class="text-danger text-sm">{{
                            errors.first("password") | text_formatter
                          }}</span>

                          <div class="flex flex-wrap justify-between my-5">
                            <!-- <vs-checkbox v-model="login_data.remember_me" class="mb-3">Remember Me</vs-checkbox> -->
                            <router-link :to="{ name: 'login-forgot' }"
                              >{{ $t("main.forgotPassword") }}?</router-link
                            >
                          </div>
                          <vs-button
                            class="float-right mb-8 submit-button"
                            :disabled="disabled"
                            @click="login">
                            {{ $t('main.login') }}</vs-button
                          >
                          <div>
                            <vs-alert
                              :active="hideAlert"
                              color="warning"
                              icon="error">
                              {{
                                $t(
                                  'main.please_wait_until_loading_data_and_make_sure_the_notification_permissions_is_allowed_in_the_browser_setting'
                                )
                              }}
                            </vs-alert>
                          </div>
                        </form>
                      </transition>
                    </vs-tab>
                  </vs-tabs>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import firebase from 'firebase'
import {authUserMixin} from '@/mixins.js'
// import '../../../../firebase-app.js'
// import '../../../../firebase-messaging.js'
// import {setupAxiosConfig} from '@/axios.js'

// API

export default {
  name: 'login',
  mixins: [authUserMixin],
  data() {
    return {
      hideAlert: false,
      disableButton: true,
      loading: false,
      disabled: false,
      login_data: {
        email: '',
        password: '',
        remember_me: false,
        type: 'web',
        device_token: ''
      }
    }
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.login_data.email !== '' &&
        this.login_data.password !== ''
      )
    },
    continueLoginAction() {
      return this.validateForm && !this.loading
    }
  },
  watch: {
    disableButton(v) {
      // alert(`disableButton : ${v}`)
    },
    validateForm(v) {
      // alert(`validateForm : ${v}`)
    }
  },
  methods: {
    ...mapActions('auth', {
      signIn: 'login'
    }),

    ////////////////////////////
    // Login
    ///////////////////////////
    login() {
      this.disabled = true;

      this.$validator
        .validate()
        .then((valid) => {
          if (valid) {
            this.signIn(this.login_data)
              .then(async (res) => {
                this.successMsg(
                  this.$t('main.You_are_authenticated'),
                  this.$t('main.Login_Success')
                )
                const user = res.user
                await this.setDefaultAuthUserSettings(user, true)
                this.disabled = false;
              })
              .catch((err) => {

                if (err.data.message) this.errMsg(err.data.message, this.$t('main.Login_Failed'))
                else
                  this.errMsg(
                    this.$t('main.Invalid_login_data'),
                    this.$t('main.Login_Failed')
                  )
                  this.disabled = false;
              })


          } else {
            this.disabled = false;

            this.errMsg();
          }
        })
        .catch((err) => this.errMsg(err))



    },

    ////////////////////////////
    // firebase
    ///////////////////////////
    retrieveToken() {
      const that = this
      if (!firebase.apps.length) {
        const firebaseConfig = {
          apiKey: 'AIzaSyCJuLiHrFLqcxxCfkCj5UeWeb8mSPsDPHs',
          authDomain: 'schoolia-53bdb.firebaseapp.com',
          projectId: 'schoolia-53bdb',
          storageBucket: 'schoolia-53bdb.appspot.com',
          messagingSenderId: '32984757712',
          appId: '1:32984757712:web:138303dc8171f5307375d5',
          measurementId: 'G-E2R9H0EEEP'
        }
        const fb = firebase.initializeApp(firebaseConfig)

        fb.messaging()
          .requestPermission()
          .then(function () {
            return fb.messaging().getToken()
          })
          .then(function (token) {
            that.login_data.device_token = token
            that.disableButton = false
          })
          .catch(function (err) {
            that.hideAlert = true
            that.disableButton = false
            console.log('Unable to get permission to notify.', err)
          })
        fb.messaging().onMessage((payload) => {
          console.log('dffff', payload)
        })
      } else {
        firebase
          .messaging()
          .requestPermission()
          .then(function () {
            return firebase.messaging().getToken()
          })
          .then(function (token) {
            that.login_data.device_token = token
            that.disableButton = false
          })
          .catch(function (err) {
            that.hideAlert = true
            that.disableButton = false
            console.log('Unable to get permission to notify.', err)
          })
        firebase.messaging().onMessage((payload) => {
          console.log('dffff', payload)
        })
      }
    },
    reloadFirebas() {
      setTimeout(this.retrieveToken, 700)
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.checkIosDevice()) this.disableButton = false
      else this.reloadFirebas()
    })
  },
  created() {
    if (Notification.permission === 'default') {
      Notification.requestPermission()
    }

  }

}

</script>


<style lang="scss">
.login-tabs-container {
  min-height: fit-content;
  min-height: -moz-fit-content;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
